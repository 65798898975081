import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formataMinuto' })
export class FormataMinutoPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (value == '0') {
      return '00';
    } else if (parseInt(value, 10) > 0 && parseInt(value, 10) < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }
}