import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { WizardComponent } from './wizard.component';
import { AppMaterialModule } from '../app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '../commons/icons.module';
import { CurrencyBRLPipe } from '../pipes/currencyBRL.pipe';
import { ImportModule } from '../import.module';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    ImportModule,
  ],
  exports: [IconsModule],
  declarations: [WizardComponent]
})
export class WizardModule { }
