import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyBRL' })
export class CurrencyBRLPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) return value;
    value = value.replace(/\./gi, '_');
    value = value.replace(/\,/gi, '.');
    value = value.replace(/\_/gi, ',');
    return value;
  }
}