import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { FilterPipe } from './pipes/filter.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CurrencyBRLPipe } from './pipes/currencyBRL.pipe';
import { FormataMinutoPipe } from './pipes/formataMinuto.pipe';


@NgModule({
    imports:[
        CommonModule,
        RouterModule,
        FormsModule,
    ],
    declarations:[
        FilterPipe,
        TruncatePipe,
        CurrencyBRLPipe,
        FormataMinutoPipe
    ],
    exports: [
        FilterPipe,
        TruncatePipe,
        CurrencyBRLPipe,
        FormataMinutoPipe
    ],
    providers:[]
})

export class ImportModule {}