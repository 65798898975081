import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements OnInit {

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  valorP1: number = 0;
  valorP2: number = 0;
  valorP3: number = 0;
  valorP4: number = 0;
  valorP5: number = 0;
  valorP6: number = 0;
  valorP7: number = 0;
  valorP8: number = 0;

  valorTotal: number = 0;

  constructor(private _formBuilder: FormBuilder) {

  }

  //  PLATAFORMAS - INICIO 

  btnP1_1: boolean = false; // android
  cssBtnP1_1: string = '';
  valueBtnP1_1: number = 15500.0;

  btnP1_2: boolean = false; // apple
  cssBtnP1_2: string = '';
  valueBtnP1_2: number = 15500.0;

  btnP1_3: boolean = false; // web
  cssBtnP1_3: string = '';
  valueBtnP1_3: number = 14000.0;

  //  PLATAFORMAS - FIM

  //  LOGIN - INICIO 

  btnP2_1: boolean = false; // login - com redes sociais
  cssBtnP2_1: string = '';
  valueBtnP2_1: number = 6500.0;

  btnP2_2: boolean = false; // login - com email e senha
  cssBtnP2_2: string = '';
  valueBtnP2_2: number = 4000.0;

  btnP2_3: boolean = false; // login - nao necessita de login
  cssBtnP2_3: string = '';
  valueBtnP2_3: number = 0.0;

  // btnP2_4: boolean = false; // loing - ainda nao sei
  // cssBtnP2_4: string = '';
  // valueBtnP2_4: number = 0.0;

  //  LOGIN - FIM

  //  RECURSOS - INICIO 

  btnP3_1: boolean = false; // recursos - geolocallizacao
  cssBtnP3_1: string = '';
  valueBtnP3_1: number = 4500.0;

  btnP3_2: boolean = false; // recursos - camera
  cssBtnP3_2: string = '';
  valueBtnP3_2: number = 2500.0;

  btnP3_3: boolean = false; // recursos - mapas
  cssBtnP3_3: string = '';
  valueBtnP3_3: number = 4500.0;

  btnP3_4: boolean = false; // recursos - leitor qrcode
  cssBtnP3_4: string = '';
  valueBtnP3_4: number = 2000.0;

  //  RECURSOS - FIM

  //  TAMANHO - INICIO 

  btnP4_1: boolean = false; // tamanho - pequeno
  cssBtnP4_1: string = '';
  valueBtnP4_1: number = 3000.0;

  btnP4_2: boolean = false; // tamanho - medio
  cssBtnP4_2: string = '';
  valueBtnP4_2: number = 5000.0;

  btnP4_3: boolean = false; // tamanho - grande
  cssBtnP4_3: string = '';
  valueBtnP4_3: number = 9000.0;

  //  TAMANHO - FIM

  //  ESTAGIO - INICIO 

  btnP5_1: boolean = false; // estagio - ideia
  cssBtnP5_1: string = '';
  valueBtnP5_1: number = 9000.0;

  btnP5_2: boolean = false; // recursos - papel
  cssBtnP5_2: string = '';
  valueBtnP5_2: number = 7000.0;

  btnP5_3: boolean = false; // recursos - pronto
  cssBtnP5_3: string = '';
  valueBtnP5_3: number = 4000.0;

  //  ESTAGIO - FIM

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  updateValorTotal() {

  }

  checkPasso(passo: number) {
    if (passo === 1) {
      return !(this.btnP1_1 || this.btnP1_2 || this.btnP1_3);
    } else if (passo === 2) {
      return !((this.btnP2_1 && this.btnP2_2) && !this.btnP2_3
        || ((this.btnP2_1 || this.btnP2_2) && !this.btnP2_3)
        || ((!this.btnP2_1 && !this.btnP2_2) && this.btnP2_3));
    } else if (passo === 3) {
      // return !(this.btnP3_1 || this.btnP3_2 || this.btnP3_3 || this.btnP3_4);
      return false;
    } else if (passo === 4) {
      return !((this.btnP4_1 && !this.btnP4_2 && !this.btnP4_3)
        || (!this.btnP4_1 && this.btnP4_2 && !this.btnP4_3)
        || (!this.btnP4_1 && !this.btnP4_2 && this.btnP4_3));
    } else if (passo === 5) {
      return !((this.btnP5_1 && !this.btnP5_2 && !this.btnP5_3)
        || (!this.btnP5_1 && this.btnP5_2 && !this.btnP5_3)
        || (!this.btnP5_1 && !this.btnP5_2 && this.btnP5_3));
    }
    return false;
  }


  setValueP1(btn: string) {
    if (btn === 'btnP1_1') {
      if (this.btnP1_1) {
        this.btnP1_1 = false;
        this.cssBtnP1_1 = '';
        this.valorTotal -= this.valueBtnP1_1;
      } else {
        this.btnP1_1 = true;
        this.cssBtnP1_1 = 'success';
        this.valorTotal += this.valueBtnP1_1;
      }
    } else if (btn === 'btnP1_2') {
      if (this.btnP1_2) {
        this.btnP1_2 = false;
        this.cssBtnP1_2 = '';
        this.valorTotal -= this.valueBtnP1_2;
      } else {
        this.btnP1_2 = true;
        this.cssBtnP1_2 = 'success';
        this.valorTotal += this.valueBtnP1_2;
      }
    } else if (btn === 'btnP1_3') {
      if (this.btnP1_3) {
        this.btnP1_3 = false;
        this.cssBtnP1_3 = '';
        this.valorTotal -= this.valueBtnP1_3;
      } else {
        this.btnP1_3 = true;
        this.cssBtnP1_3 = 'success';
        this.valorTotal += this.valueBtnP1_3;
      }
    }
  }

  setValueP2(btn: string) {
    if (btn === 'btnP2_1') {
      if (this.btnP2_1) {
        this.btnP2_1 = false;
        this.cssBtnP2_1 = '';
        this.valorTotal -= this.valueBtnP2_1;
      } else {
        this.btnP2_1 = true;
        this.cssBtnP2_1 = 'success';
        this.valorTotal += this.valueBtnP2_1;
        if (this.btnP2_3) {
          this.btnP2_3 = false;
          this.cssBtnP2_3 = '';
          this.valorTotal -= this.valueBtnP2_3;
        }
      }
    } else if (btn === 'btnP2_2') {
      if (this.btnP2_2) {
        this.btnP2_2 = false;
        this.cssBtnP2_2 = '';
        this.valorTotal -= this.valueBtnP2_2;
      } else {
        this.btnP2_2 = true;
        this.cssBtnP2_2 = 'success';
        this.valorTotal += this.valueBtnP2_2;
        if (this.btnP2_3) {
          this.btnP2_3 = false;
          this.cssBtnP2_3 = '';
          this.valorTotal -= this.valueBtnP2_3;
        }
      }
    } else if (btn === 'btnP2_3') {
      if (this.btnP2_3) {
        this.btnP2_3 = false;
        this.cssBtnP2_3 = '';
        this.valorTotal -= this.valueBtnP2_3;
      } else {
        this.btnP2_3 = true;
        this.cssBtnP2_3 = 'success';
        this.valorTotal += this.valueBtnP2_3;
        if (this.btnP2_1) {
          this.btnP2_1 = false;
          this.cssBtnP2_1 = '';
          this.valorTotal -= this.valueBtnP2_1;
        }
        if (this.btnP2_2) {
          this.btnP2_2 = false;
          this.cssBtnP2_2 = '';
          this.valorTotal -= this.valueBtnP2_2;
        }
      }
      // } else if (btn === 'btnP2_4') {
      //   if (this.btnP2_4) {
      //     this.btnP2_4 = false;
      //     this.cssBtnP2_4 = '';
      //     this.valorTotal -= this.valueBtnP2_4;
      //   } else {
      //     this.btnP2_4 = true;
      //     this.cssBtnP2_4 = 'success';
      //     this.valorTotal += this.valueBtnP2_4;
      //   }
    }
  }

  setValueP3(btn: string) {
    if (btn === 'btnP3_1') {
      if (this.btnP3_1) {
        this.btnP3_1 = false;
        this.cssBtnP3_1 = '';
        this.valorTotal -= this.valueBtnP3_1;
      } else {
        this.btnP3_1 = true;
        this.cssBtnP3_1 = 'success';
        this.valorTotal += this.valueBtnP3_1;
      }
    } else if (btn === 'btnP3_2') {
      if (this.btnP3_2) {
        this.btnP3_2 = false;
        this.cssBtnP3_2 = '';
        this.valorTotal -= this.valueBtnP3_2;
      } else {
        this.btnP3_2 = true;
        this.cssBtnP3_2 = 'success';
        this.valorTotal += this.valueBtnP3_2;
      }
    } else if (btn === 'btnP3_3') {
      if (this.btnP3_3) {
        this.btnP3_3 = false;
        this.cssBtnP3_3 = '';
        this.valorTotal -= this.valueBtnP3_3;
      } else {
        this.btnP3_3 = true;
        this.cssBtnP3_3 = 'success';
        this.valorTotal += this.valueBtnP3_3;
      }
    } else if (btn === 'btnP3_4') {
      if (this.btnP3_4) {
        this.btnP3_4 = false;
        this.cssBtnP3_4 = '';
        this.valorTotal -= this.valueBtnP3_4;
      }
      else {
        this.btnP3_4 = true;
        this.cssBtnP3_4 = 'success';
        this.valorTotal += this.valueBtnP3_4;
      }
    }
  }

  setValueP4(btn: string) {
    if (btn === 'btnP4_1') {
      if (this.btnP4_1) {
        this.btnP4_1 = false;
        this.cssBtnP4_1 = '';
        this.valorTotal -= this.valueBtnP4_1;
      } else {
        this.btnP4_1 = true;
        this.cssBtnP4_1 = 'success';
        this.valorTotal += this.valueBtnP4_1;

        if (this.btnP4_2) {
          this.btnP4_2 = false;
          this.cssBtnP4_2 = '';
          this.valorTotal -= this.valueBtnP4_2;
        }
        if (this.btnP4_3) {
          this.btnP4_3 = false;
          this.cssBtnP4_3 = '';
          this.valorTotal -= this.valueBtnP4_3;
        }

      }
    } else if (btn === 'btnP4_2') {
      if (this.btnP4_2) {
        this.btnP4_2 = false;
        this.cssBtnP4_2 = '';
        this.valorTotal -= this.valueBtnP4_2;
      } else {
        this.btnP4_2 = true;
        this.cssBtnP4_2 = 'success';
        this.valorTotal += this.valueBtnP4_2;

        if (this.btnP4_1) {
          this.btnP4_1 = false;
          this.cssBtnP4_1 = '';
          this.valorTotal -= this.valueBtnP4_1;
        }
        if (this.btnP4_3) {
          this.btnP4_3 = false;
          this.cssBtnP4_3 = '';
          this.valorTotal -= this.valueBtnP4_3;
        }

      }
    } else if (btn === 'btnP4_3') {
      if (this.btnP4_3) {
        this.btnP4_3 = false;
        this.cssBtnP4_3 = '';
        this.valorTotal -= this.valueBtnP4_3;
      } else {
        this.btnP4_3 = true;
        this.cssBtnP4_3 = 'success';
        this.valorTotal += this.valueBtnP4_3;

        if (this.btnP4_1) {
          this.btnP4_1 = false;
          this.cssBtnP4_1 = '';
          this.valorTotal -= this.valueBtnP4_1;
        }
        if (this.btnP4_2) {
          this.btnP4_2 = false;
          this.cssBtnP4_2 = '';
          this.valorTotal -= this.valueBtnP4_2;
        }

      }
    }
  }

  setValueP5(btn: string) {
    if (btn === 'btnP5_1') {
      if (this.btnP5_1) {
        this.btnP5_1 = false;
        this.cssBtnP5_1 = '';
        this.valorTotal -= this.valueBtnP5_1;
      } else {
        this.btnP5_1 = true;
        this.cssBtnP5_1 = 'success';
        this.valorTotal += this.valueBtnP5_1;

        if (this.btnP5_2) {
          this.btnP5_2 = false;
          this.cssBtnP5_2 = '';
          this.valorTotal -= this.valueBtnP5_2;
        }
        if (this.btnP5_3) {
          this.btnP5_3 = false;
          this.cssBtnP5_3 = '';
          this.valorTotal -= this.valueBtnP5_3;
        }

      }
    } else if (btn === 'btnP5_2') {
      if (this.btnP5_2) {
        this.btnP5_2 = false;
        this.cssBtnP5_2 = '';
        this.valorTotal -= this.valueBtnP5_2;
      } else {
        this.btnP5_2 = true;
        this.cssBtnP5_2 = 'success';
        this.valorTotal += this.valueBtnP5_2;

        if (this.btnP5_1) {
          this.btnP5_1 = false;
          this.cssBtnP5_1 = '';
          this.valorTotal -= this.valueBtnP5_1;
        }
        if (this.btnP5_3) {
          this.btnP5_3 = false;
          this.cssBtnP5_3 = '';
          this.valorTotal -= this.valueBtnP5_3;
        }

      }
    } else if (btn === 'btnP5_3') {
      if (this.btnP5_3) {
        this.btnP5_3 = false;
        this.cssBtnP5_3 = '';
        this.valorTotal -= this.valueBtnP5_3;
      } else {
        this.btnP5_3 = true;
        this.cssBtnP5_3 = 'success';
        this.valorTotal += this.valueBtnP5_3;

        if (this.btnP5_1) {
          this.btnP5_1 = false;
          this.cssBtnP5_1 = '';
          this.valorTotal -= this.valueBtnP5_1;
        }
        if (this.btnP5_2) {
          this.btnP5_2 = false;
          this.cssBtnP5_2 = '';
          this.valorTotal -= this.valueBtnP5_2;
        }

      }
    }
  }

  comecarNovamente() {
    console.log('comecarNovamente');
  }

  share(socialNetwork) {
    const encodedURI = encodeURIComponent('https://app.indh.io');
    if (socialNetwork === 'facebook') {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodedURI, 'facebook-share-dialog', 'width=626,height=436');
    } else if (socialNetwork === 'linkedin') {
      window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodedURI
        + '&title=INDH.IO&summaryQuanto%20custa%20criar%20um%20aplicativo?&source=LinkedIn', 'LinkedIn', 'width=626,height=436');
    } else if (socialNetwork === 'twitter') {
      window.open('https://twitter.com/intent/tweet/?text=Quanto%20custa%20criar%20um%20aplicativo?&url=' + encodedURI, 'Twitter', 'width=626,height=436');
    }
  }

  goToWhatsApp() {
    const text = 'Fiz uma simulação de um sistema web e/ou app no site https://app.indh.io/ e gostaria de mais informações. Poderia me ajudar?';
    const link = 'https://api.whatsapp.com/send?phone=+14054584262‬&text=' + text;
    window.open(link, '_blank');
  }

  goToTelegram() {
    // const text = 'Fiz uma simulação de um sistema web e/ou app no site https://app.indh.io/ e gostaria de mais informações. Poderia me ajudar?';
    // const link = 'https://t.me/indhio&text=' + text;
    const link = 'https://t.me/indhio';
    window.open(link, '_blank');
  }

}
