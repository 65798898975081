import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
    transform(items: any[], fields: string | string[], value: string): any[] {
        if (!items) {
            return [];
        }
        if (!fields || !value) {
            return items;
        }

        if (typeof fields === "string") {
            return items.filter(singleItem => {
                console.log(singleItem);
                let include = singleItem[fields].toString().toLowerCase().includes(value.toString().toLowerCase());
                console.log(include);
                return include;
            });
        } else {
            return items.filter(singleItem => {
                let result = false;
                for (let i = 0; i < fields.length; i++) {
                    if (singleItem[fields[i]] && singleItem[fields[i]].toString().toLowerCase().includes(value.toString().toLowerCase())) {
                        result = true;
                        break;
                    }
                }
                return result;
            });
        }

    }
}